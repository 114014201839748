import React from "react";
import theme from "theme";
import { Theme, Text, Box, Span, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				Luxury Drive Rentals
			</title>
			<meta name={"description"} content={"Відчуйте подорож на все життя"} />
			<meta property={"og:title"} content={"Luxury Drive Rentals"} />
			<meta property={"og:description"} content={"Відчуйте подорож на все життя"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6662dabafdcae00021e301cc/images/2-1.jpg?v=2024-06-19T10:17:01.519Z"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="96px 0 24px 0"
			xl-padding="80px 0 20px 0"
			lg-padding="64px 0 16px 0"
			md-padding="48px 0 8px 0"
			sm-padding="36px 0 0px 0"
			quarkly-title="Reviews-15"
		>
			<Override slot="SectionContent" max-width="800px" />
			<Text
				font="--headline1"
				color="--dark"
				letter-spacing=".02em"
				margin="0px 0px 32px 0px"
				xl-margin="0px 0px 24px 0px"
				lg-margin="0px 0px 20px 0px"
				md-margin="0px 0px 16px 0px"
				sm-margin="0px 0px 8px 0px"
			>
				FAQ
			</Text>
			<Box
				quarkly-title="Box"
				margin="0px 0px 80px 0px"
				xl-margin="0px 0px 64px 0px"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 36px 0px"
				sm-margin="0px 0px 24px 0px"
			>
				<Text
					font="--headline3"
					color="--dark"
					letter-spacing=".02em"
					lg-font="normal 400 24px/1.3 &quot;Libre Caslon Display&quot;, serif"
					xl-font="normal 400 30px/1.3 &quot;Libre Caslon Display&quot;, serif"
					md-font="normal 400 20px/1.3 &quot;Libre Caslon Display&quot;, serif"
					margin="0px 0px 32px 0px"
					xl-margin="0px 0px 24px 0px"
					lg-margin="0px 0px 20px 0px"
					md-margin="0px 0px 16px 0px"
					sm-margin="0px 0px 8px 0px"
				>
					Як забронювати автомобіль?
				</Text>
				<Text font="normal 400 20px/1.8 --fontFamily-googleLibreCaslonDisplay" letter-spacing=".02em" color="--dark" margin="0px 0px 0px 0px">
					Щоб забронювати автомобіль, ви можете скористатися нашим онлайн-формою бронювання на веб-сайті або зателефонувати до нашого офісу. Вкажіть бажану дату, час та місце отримання автомобіля, а також виберіть бажану модель з нашого каталогу.
				</Text>
			</Box>
			<Box
				quarkly-title="Box"
				margin="0px 0px 80px 0px"
				xl-margin="0px 0px 64px 0px"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 36px 0px"
				sm-margin="0px 0px 24px 0px"
			>
				<Text
					font="--headline3"
					color="--dark"
					letter-spacing=".02em"
					lg-font="normal 400 24px/1.3 &quot;Libre Caslon Display&quot;, serif"
					xl-font="normal 400 30px/1.3 &quot;Libre Caslon Display&quot;, serif"
					md-font="normal 400 20px/1.3 &quot;Libre Caslon Display&quot;, serif"
					margin="0px 0px 32px 0px"
					xl-margin="0px 0px 24px 0px"
					lg-margin="0px 0px 20px 0px"
					md-margin="0px 0px 16px 0px"
					sm-margin="0px 0px 8px 0px"
				>
					Які документи необхідні для оренди автомобіля?
				</Text>
				<Text font="normal 400 20px/1.8 --fontFamily-googleLibreCaslonDisplay" letter-spacing=".02em" color="--dark" margin="0px 0px 0px 0px">
					Для оренди автомобіля вам знадобляться дійсні посвідчення водія, паспорт або інший документ, що підтверджує особу, та кредитна карта на ваше ім'я.
				</Text>
			</Box>
			<Box
				quarkly-title="Box"
				margin="0px 0px 80px 0px"
				xl-margin="0px 0px 64px 0px"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 36px 0px"
				sm-margin="0px 0px 24px 0px"
			>
				<Text
					font="--headline3"
					color="--dark"
					letter-spacing=".02em"
					lg-font="normal 400 24px/1.3 &quot;Libre Caslon Display&quot;, serif"
					xl-font="normal 400 30px/1.3 &quot;Libre Caslon Display&quot;, serif"
					md-font="normal 400 20px/1.3 &quot;Libre Caslon Display&quot;, serif"
					margin="0px 0px 32px 0px"
					xl-margin="0px 0px 24px 0px"
					lg-margin="0px 0px 20px 0px"
					md-margin="0px 0px 16px 0px"
					sm-margin="0px 0px 8px 0px"
				>
					Чи можна орендувати автомобіль без кредитної карти?
				</Text>
				<Text font="normal 400 20px/1.8 --fontFamily-googleLibreCaslonDisplay" letter-spacing=".02em" color="--dark" margin="0px 0px 0px 0px">
					На жаль, на даний момент ми приймаємо тільки кредитні карти для оплати та забезпечення депозиту. Це необхідно для гарантії безпеки наших клієнтів та компанії.
				</Text>
			</Box>
			<Box
				quarkly-title="Box"
				margin="0px 0px 80px 0px"
				xl-margin="0px 0px 64px 0px"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 36px 0px"
				sm-margin="0px 0px 24px 0px"
			>
				<Text
					font="--headline3"
					color="--dark"
					letter-spacing=".02em"
					lg-font="normal 400 24px/1.3 &quot;Libre Caslon Display&quot;, serif"
					xl-font="normal 400 30px/1.3 &quot;Libre Caslon Display&quot;, serif"
					md-font="normal 400 20px/1.3 &quot;Libre Caslon Display&quot;, serif"
					margin="0px 0px 32px 0px"
					xl-margin="0px 0px 24px 0px"
					lg-margin="0px 0px 20px 0px"
					md-margin="0px 0px 16px 0px"
					sm-margin="0px 0px 8px 0px"
				>
					Які є варіанти страхування?
				</Text>
				<Text font="normal 400 20px/1.8 --fontFamily-googleLibreCaslonDisplay" letter-spacing=".02em" color="--dark" margin="0px 0px 0px 0px">
					Ми пропонуємо різні варіанти страхування для вашої зручності та безпеки, включаючи стандартне страхування, страхування від крадіжки та повне страхування з нульовою відповідальністю. Деталі та вартість страхування можна знайти на нашому веб-сайті або уточнити у нашого менеджера.
				</Text>
			</Box>
			<Box
				quarkly-title="Box"
				margin="0px 0px 80px 0px"
				xl-margin="0px 0px 64px 0px"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 36px 0px"
				sm-margin="0px 0px 24px 0px"
			>
				<Text
					font="--headline3"
					color="--dark"
					letter-spacing=".02em"
					lg-font="normal 400 24px/1.3 &quot;Libre Caslon Display&quot;, serif"
					xl-font="normal 400 30px/1.3 &quot;Libre Caslon Display&quot;, serif"
					md-font="normal 400 20px/1.3 &quot;Libre Caslon Display&quot;, serif"
					margin="0px 0px 32px 0px"
					xl-margin="0px 0px 24px 0px"
					lg-margin="0px 0px 20px 0px"
					md-margin="0px 0px 16px 0px"
					sm-margin="0px 0px 8px 0px"
				>
					Чи можу я повернути автомобіль у іншому місці?
				</Text>
				<Text font="normal 400 20px/1.8 --fontFamily-googleLibreCaslonDisplay" letter-spacing=".02em" color="--dark" margin="0px 0px 0px 0px">
					Так, ви можете повернути автомобіль в іншому місці за попередньою домовленістю. Будь ласка, повідомте нас про це під час бронювання, щоб ми могли організувати зручне для вас місце повернення.
				</Text>
			</Box>
			<Box
				quarkly-title="Box"
				margin="0px 0px 80px 0px"
				xl-margin="0px 0px 64px 0px"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 36px 0px"
				sm-margin="0px 0px 24px 0px"
			>
				<Text
					font="--headline3"
					color="--dark"
					letter-spacing=".02em"
					lg-font="normal 400 24px/1.3 &quot;Libre Caslon Display&quot;, serif"
					xl-font="normal 400 30px/1.3 &quot;Libre Caslon Display&quot;, serif"
					md-font="normal 400 20px/1.3 &quot;Libre Caslon Display&quot;, serif"
					margin="0px 0px 32px 0px"
					xl-margin="0px 0px 24px 0px"
					lg-margin="0px 0px 20px 0px"
					md-margin="0px 0px 16px 0px"
					sm-margin="0px 0px 8px 0px"
				>
					Що робити у випадку аварії або поломки?
				</Text>
				<Text font="normal 400 20px/1.8 --fontFamily-googleLibreCaslonDisplay" letter-spacing=".02em" color="--dark" margin="0px 0px 0px 0px">
					У випадку аварії або поломки, негайно зв'яжіться з нашою службою підтримки, номер якої вказаний у договорі оренди. Ми організуємо допомогу на дорозі та заміну автомобіля, якщо це необхідно.
				</Text>
			</Box>
			<Box
				quarkly-title="Box"
				margin="0px 0px 80px 0px"
				xl-margin="0px 0px 64px 0px"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 36px 0px"
				sm-margin="0px 0px 24px 0px"
			>
				<Text
					font="--headline3"
					color="--dark"
					letter-spacing=".02em"
					lg-font="normal 400 24px/1.3 &quot;Libre Caslon Display&quot;, serif"
					xl-font="normal 400 30px/1.3 &quot;Libre Caslon Display&quot;, serif"
					md-font="normal 400 20px/1.3 &quot;Libre Caslon Display&quot;, serif"
					margin="0px 0px 32px 0px"
					xl-margin="0px 0px 24px 0px"
					lg-margin="0px 0px 20px 0px"
					md-margin="0px 0px 16px 0px"
					sm-margin="0px 0px 8px 0px"
				>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Як скасувати або змінити бронювання?
					</Span>
				</Text>
				<Text font="normal 400 20px/1.8 --fontFamily-googleLibreCaslonDisplay" letter-spacing=".02em" color="--dark" margin="0px 0px 0px 0px">
					Ви можете скасувати або змінити своє бронювання, зв'язавшись з нами по телефону або через онлайн-форму на нашому веб-сайті. Зверніть увагу, що можуть застосовуватися правила скасування та штрафи в залежності від часу скасування.
				</Text>
			</Box>
			<Box
				quarkly-title="Box"
				margin="0px 0px 80px 0px"
				xl-margin="0px 0px 64px 0px"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 36px 0px"
				sm-margin="0px 0px 24px 0px"
			>
				<Text
					font="--headline3"
					color="--dark"
					letter-spacing=".02em"
					lg-font="normal 400 24px/1.3 &quot;Libre Caslon Display&quot;, serif"
					xl-font="normal 400 30px/1.3 &quot;Libre Caslon Display&quot;, serif"
					md-font="normal 400 20px/1.3 &quot;Libre Caslon Display&quot;, serif"
					margin="0px 0px 32px 0px"
					xl-margin="0px 0px 24px 0px"
					lg-margin="0px 0px 20px 0px"
					md-margin="0px 0px 16px 0px"
					sm-margin="0px 0px 8px 0px"
				>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Чи є обмеження по пробігу?
					</Span>
				</Text>
				<Text font="normal 400 20px/1.8 --fontFamily-googleLibreCaslonDisplay" letter-spacing=".02em" color="--dark" margin="0px 0px 0px 0px">
					Деякі з наших тарифів включають необмежений пробіг, але інші можуть мати обмеження. Уточнюйте ці деталі під час бронювання, щоб вибрати найбільш підходящий варіант для вас.
				</Text>
			</Box>
			<Box
				quarkly-title="Box"
				margin="0px 0px 80px 0px"
				xl-margin="0px 0px 64px 0px"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 36px 0px"
				sm-margin="0px 0px 24px 0px"
			>
				<Text
					font="--headline3"
					color="--dark"
					letter-spacing=".02em"
					lg-font="normal 400 24px/1.3 &quot;Libre Caslon Display&quot;, serif"
					xl-font="normal 400 30px/1.3 &quot;Libre Caslon Display&quot;, serif"
					md-font="normal 400 20px/1.3 &quot;Libre Caslon Display&quot;, serif"
					margin="0px 0px 32px 0px"
					xl-margin="0px 0px 24px 0px"
					lg-margin="0px 0px 20px 0px"
					md-margin="0px 0px 16px 0px"
					sm-margin="0px 0px 8px 0px"
				>
					Чи можна орендувати автомобіль з водієм?
				</Text>
				<Text font="normal 400 20px/1.8 --fontFamily-googleLibreCaslonDisplay" letter-spacing=".02em" color="--dark" margin="0px 0px 0px 0px">
					Так, ми пропонуємо послугу оренди автомобіля з професійним водієм. Це ідеальний варіант для ділових поїздок або особливих подій. Детальнішу інформацію ви можете знайти на нашому веб-сайті або звернутися до нашого менеджера.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65c4dc440072400020e9c6f3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});